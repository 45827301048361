import React from 'react';
import { Box } from '@interstate/components/Box';
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';
import { Tabs } from '@interstate/components/Tabs';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { QnA } from 'scenes/qna/qna';
import styled from 'styled-components';
import { MFPayload } from 'models/MFPayload';
import { VehicleInfoScene } from 'scenes/vehicleInfo/vehicleInfo';

export default function TRAHomeScene(props: MFPayload) {
  const handleDone = () => {
    props.onNavigate(false);
  };

  return (
    <InterstateThemeProvider
      themeName="Interstate"
      applicationName="TRA Inspection"
      scopeName="TRA Inspection"
      themeRegistries={[interstateThemeRegistry]}
    >
      <StyledBox>
        <StyledGrid container spacing={2}>
          <Grid xs={12} sx={{ marginTop: '5px' }}>
            <Typography tag="span" variant="h1" color="base.color.blue.800">
              TRA Inspection
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography tag="span" variant="h6" color="base.color.blue.800">
              {props.userDetails?.userName} {'-'}
              {props.userDetails?.emailId}
            </Typography>
          </Grid>
          <Tabs
            data-testid="default-data-testid"
            id="default-id"
            onActivate={function Ua() {}}
            tabs={[
              {
                component: <VehicleInfoScene {...props} />,
                label: 'Info',
                value: 'Vehicle Info',
                sx: { color: 'base.color.blue.800' },
                active: true
              },
              {
                component: <QnA {...props} />,
                label: 'QnA',
                value: 'Questions',
                sx: { color: 'base.color.blue.800' }
              },
              {
                component: (
                  <div>
                    Photos content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia corporis nostrum
                    repudiandae pariatur sed doloribus. Voluptatem, perspiciatis placeat nostrum nemo doloremque nobis
                    enim quibusdam nesciunt, nulla fugit ex, natus rerum?
                  </div>
                ),
                label: 'Photos',
                value: 'photos',
                sx: { color: 'base.color.blue.800' }
              },
              {
                component: (
                  <div>
                    Review content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia corporis nostrum
                    repudiandae pariatur sed doloribus. Voluptatem, perspiciatis placeat nostrum nemo doloremque nobis
                    enim quibusdam nesciunt, nulla fugit ex, natus rerum?
                  </div>
                ),
                label: 'Review',
                value: 'review',
                sx: { color: 'base.color.blue.800' }
              }
            ]}
          />
          <Grid xs={12} sx={{ marginTop: '90px' }}>
            <PrimaryButon block type="submit" onClick={handleDone}>
              DONE
            </PrimaryButon>
          </Grid>
        </StyledGrid>
      </StyledBox>
    </InterstateThemeProvider>
  );
}

const StyledBox = styled(Box)`
  background: white;
  min-height: 100vh;
`;

const StyledGrid = styled(Grid)`
  width: 90%;
  margin: auto !important;
`;

const PrimaryButon = styled(Button)``;
