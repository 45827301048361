import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@interstate/components/Box';
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { TextInput } from '@interstate/components/TextInput';
import { ScanResult } from 'models/ScanResult';
import { MFPayload } from 'models/MFPayload';

export const VehicleInfoScene = (props: MFPayload) => {
  const [workOrder, setWorkOrder] = useState<string | undefined>();

  useEffect(() => {
    document.addEventListener('onScanComplete', ((e: CustomEvent<ScanResult>) =>
      handleOnScanComplete(e.detail)) as EventListener);

    return () => {
      document.removeEventListener('onScanComplete', ((e: CustomEvent<ScanResult>) =>
        handleOnScanComplete(e.detail)) as EventListener);
    };
  }, []);

  const handleOnScanComplete = (event: ScanResult) => {
    console.log(event.result);
    setWorkOrder(event.result);
  };

  const handleScan = () => {
    props.openScanner();
  };

  const handleCapture = () => {
    props.openFyuseCapture();
  };

  return (
    <StyledBox>
      <StyledGrid container spacing={2}>
        <Grid xs={12} sx={{ marginTop: '30px' }}>
          <TextInput
            id="scanner_wo_input"
            data-testid="scanner_wo_input"
            name="Work Order Number"
            maxLength={17}
            placeholder="Work Order or VIN"
            value={workOrder}
          />
        </Grid>
        <Grid xs={12} sx={{ marginTop: '10px' }}>
          <PrimaryButon block type="submit" onClick={handleScan}>
            SCAN
          </PrimaryButon>
        </Grid>
        <Grid xs={12}>
          <PrimaryButon block type="submit" onClick={handleCapture}>
            CAPTURE
          </PrimaryButon>
        </Grid>
      </StyledGrid>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background: white;
  min-height: 100vh;
`;
const StyledGrid = styled(Grid)`
  width: 90%;
  margin: auto !important;
`;
const PrimaryButon = styled(Button)``;
